import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import axios from 'axios';

function HomeBlog() {

    const [data, setdata] = useState([]);
    useEffect(() => {
        axios.get("https://backend.brightvisionchemicalmanufacturingplc.com.et/blogfetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id).slice(0, 3))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);
    


  return (

    <div className="container">
    {data.length>0 &&  <div className=" blog pb-5 mb-2">
            <div className=" section-title text-center   mb-3" data-wow-delay="0.1s">
            <div className="sub-style ">
                <h5 className="sub-title text-primary px-2">NEWS</h5>
            </div>
        
           
        </div>
                <div className="row g-4 justify-content-center">
                {data.map((blog,i)=>{
                    return         <div className="col-md-6 col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.1s" key={i}>
                        <div class="blog-item bg-light rounded p-4" style={{ backgroundImage: 'url(./img/bg.png)' }}>
                            <div className="mb-4">
                               
                                <div className="d-flex justify-content-between">
                                    <p className="mb-0"><span class="text-dark fw-bold">On</span> {blog.date}</p>
                                    <p className="mb-0"><span class="text-dark fw-bold">By</span> Admin</p>
                                </div>
                            </div>
                            <div className="project-img">
                                <img src={'https://backend.brightvisionchemicalmanufacturingplc.com.et/images/' + blog.image} class="img-fluid w-100 rounded" alt="Bright news"/>
                                <div className="blog-plus-icon">
                                   
                                </div>
                            </div>
                            <div className="my-4 text-center">
                            <Link to="/" className="h4">{blog.subject}</Link>
                        </div>
                        <div className='d-flex justify-content-center'>
                        <Link to={`/blogdetail/${blog.id}`} class="btn btn-primary rounded-pill py-2 px-4 btn-center" >Explore More</Link>
                        </div>
                        </div>
                    </div>
                })}
                    
                </div>
            </div>}
            </div>
  )
}

export default HomeBlog