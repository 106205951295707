import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';

import OwlCarousel from 'react-owl-carousel2';

import  team from './img/wend.avif'
import axios from 'axios';
import { Link } from 'react-router-dom';

const options = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000, // Time for each testimonial in ms
    dots: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1, // 1 item for mobile
      },
      600: {
        items: 2, // 2 items for tablets and up
      },
      990: {
        items: 4, // 2 items for tablets and up
      },
    },
  };


  


function Team() {

    
        const carouselRef = useRef(); // Reference to the OwlCarousel
    
        const goToNext = () => {
          if (carouselRef.current) {
            carouselRef.current.next();
          }
        };
      
        const goToPrev = () => {
          if (carouselRef.current) {
            carouselRef.current.prev();
          }
        };

        const [data, setdata] = useState([]);
        useEffect(() => {
            axios.get("https://backend.brightvisionchemicalmanufacturingplc.com.et/teamfetch")
            .then(res=>{
        if (res.data.status==="success") {
            setdata(res.data.Result.sort((a, b) => b.id - a.id))
            
        }
                
            })
            .catch(err=>console.log(err))
           
        }, []);




  return (
    

<div className="team pb-3  container-fluid">
{data.length>0 &&   <div className=" section-title text-center  mb-3" data-wow-delay="0.1s">
<div className="sub-style ">
    <h5 className="sub-title text-primary px-2">Our Team</h5>
</div>

</div>}
{data.length>0 &&   <OwlCarousel ref={carouselRef} options={options} >
       
        {data.map((teams,i)=>{
            return  <div className="wow  fadeInUp   " data-wow-delay="0.1s" key={i}>
 <div className="team-item rounded mx-md-2 ">
              <div className="team-img">
                  <img src={'https://backend.brightvisionchemicalmanufacturingplc.com.et/images/' + teams.Image} className="img-fluid w-100 rounded-top" alt="beright"/>
                  <div className="team-icon">
                      <Link className="btn btn-primary btn-sm-square text-white rounded-circle mb-3" ><i className="fas fa-share-alt"></i></Link>
                      <div className="team-icon-share">
                      {teams.Facebook && <Link class="btn btn-square btn-primary rounded-circle d-flex justify-content-center mb-3" to={teams.Facebook} target="_blank"><i class="fab fa-facebook-f"></i></Link>}
                      {teams.Tweeter &&  <Link class="btn btn-square btn-primary rounded-circle d-flex justify-content-center mb-3" to={teams.Tweeter} target="_blank"><i class="bi bi-twitter-x"></i></Link>}
                      {teams.Instagram &&  <Link class="btn btn-square btn-primary rounded-circle d-flex justify-content-center mb-3" to={teams.Instagram} target="_blank"><i class="fab fa-telegram"></i></Link>}
                         
                      </div>
                  </div>
              </div>
              <div className="team-content bg-dark text-center rounded-bottom p-4">
                  <div className="team-content-inner rounded-bottom">
                      <h4 className="text-light">{teams.Name}</h4>
                      <h5 className="text-light mb-0">{teams.Position}</h5>
                  </div>
              </div>
          </div>
          </div> 
        })}

     
</OwlCarousel>}


{data.length>0 &&   <div className="d-flex justify-content-end px-3 my-3">
        <button className=" owl-prevw mx-1" onClick={goToPrev}>←</button>
        <button className=" owl-nextw" onClick={goToNext}>→</button>
      </div>}
   </div>
  
  )
}

export default Team