
import React, { useEffect, useRef, useState } from 'react';


import OwlCarousel from 'react-owl-carousel2';


import  testimonial from './img/testimonial.jpg'
import axios from 'axios';
const options = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000, // Time for each testimonial in ms
    dots: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1, // 1 item for mobile
      },
      768: {
        items: 2, // 2 items for tablets and up
      },
    },
  };


  
const Testimonials = () => {
    const carouselRef = useRef(); // Reference to the OwlCarousel

    const goToNext = () => {
      if (carouselRef.current) {
        carouselRef.current.next();
      }
    };
  
    const goToPrev = () => {
      if (carouselRef.current) {
        carouselRef.current.prev();
      }
    };

    const [datat, setdatat] = useState([]);
  useEffect(() => {
      axios.get("https://backend.brightvisionchemicalmanufacturingplc.com.et/testimonfetch")
      .then(res=>{
  if (res.data.status==="success") {
      setdatat(res.data.Result.sort((a, b) => b.id - a.id))
      
  }
          
      })
      .catch(err=>console.log(err))
     
  }, []);



    return(
    
    <div className="  testimonial   container-fluid ">
    {datat.length>0 &&   <div className=" section-title text-center  mb-3" data-wow-delay="0.1s">
    <div className="sub-style ">
        <h5 className="sub-title text-primary px-2">Testimony</h5>
    </div>

</div>}
{datat.length>0 &&  <div className="row g-4 align-items-center">
        <div className="col-xl-4">
          
        <img src={testimonial} className="img-fluid w-100 rounded-top bg-white" alt="beright"/>
          
        </div>
        <div className="col-xl-8">
          
            <OwlCarousel ref={carouselRef} options={options}>
            
            {datat.map((testi,i)=>{
              return        <div className="testimonial-item bg-white rounded p-4" key={i}>
                <div className="d-flex">
                  <div>
                    <i className="fas fa-quote-left fa-3x text-dark me-3"></i>
                  </div>
                  <p className="mt-4">
                  {testi.Message}
                  </p>
                </div>
                <div className="d-flex justify-content-end">
                  <div className="my-auto text-end">
                    <h4>{testi.Name}</h4>
                    <h className="mb-0">{testi.Position}</h>
                  </div>
                  <div className="bg-white rounded-circle ms-3">
                    <img
                    src={'https://backend.brightvisionchemicalmanufacturingplc.com.et/images/' + testi.Image} 
                      className="rounded-circle p-2"
                      style={{ width: '80px', height: '80px', border: '1px solid', borderColor: 'var(--bs-primary)' }}
                      alt="Testimonial 1"
                    />
                  </div>
                </div>
              </div>
            })}
            
             
            </OwlCarousel>
            <div className="d-flex justify-content-end px-3 my-3">
            <button className=" owl-prevw mx-1" onClick={goToPrev}>←</button>
            <button className=" owl-nextw" onClick={goToNext}>→</button>
          </div>
        </div>
      </div>}
    </div>

)};

export default Testimonials;


