import React from 'react'
import Order from './Order';
import { Helmet } from 'react-helmet';
function OrderSi() {
  return (
    <div className=''>
    <Helmet>
    <title>Order- Be Right Vision</title>
    <meta name="description" content="Be Right Vision Manufacturing is a leading producer of household and personal care products dedicated to quality and customer satisfaction. From laundry detergents to hand washes, our products are trusted for their effectiveness and safety. We believe in making a difference through innovation and sustainability, serving communities with products that improve lives." />
  </Helmet>
    <div id="phone-tada" class="d-flex align-items-center justify-content-center me-4 fixed-bottom">
    <a href="tel:+251911351866" class="position-relative animated tada infinite">
        <i class="fa fa-phone-alt text-light fa-2x bg-danger rounded-circle p-3"></i>
        
        <span class="ring-wave"></span>
        <span class="ring-wave"></span>
    </a>
  </div>


    <div className="  bg-breadcrumb mb-5">
    <div className="bg-breadcrumb-single"></div>
    <div className="container text-center py-5" style={{maxWidth: '900px'}}>
        <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">Order Us</h4>
        <ol className="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
            <li className="breadcrumb-item"><a href="index.html"></a></li>
            <li className="breadcrumb-item"><a href="#"></a></li>
            <li className="breadcrumb-item active text-success"></li>
        </ol>    
    </div>
   </div>

    <Order/>
    </div>
  )
}

export default OrderSi