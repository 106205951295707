import React, { useEffect, useState } from 'react'
import './SingleBlog.css';

import axios from 'axios';


import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';


function BlogDetail() {
    const [data, setdata] = useState([]);
    const [latest, setlatest] = useState([]);
    const { id } = useParams();
    const [comment, setcomment] = useState({
      name:'',
      email:'',
      text:''
    });
    const [commentfetchd, setcommentfetchd] = useState([]);

    useEffect(() => {
        axios.get('https://backend.brightvisionchemicalmanufacturingplc.com.et/detail/' + id)
          .then(res => {
            if (res.data.status === "success") {
              setdata(res.data.Result)
             
            }
          })
          .catch(err => console.log(err))
      }, [id]);



      useEffect(() => {
        axios.get("https://backend.brightvisionchemicalmanufacturingplc.com.et/blogfetch")
        .then(res=>{
    if (res.data.status==="success") {
      
        setlatest(res.data.Result.sort((a, b) => b.id - a.id).slice(0, 5))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);

    

    const commenthandle=(e)=>{
      e.preventDefault()
      
    console.log(Date());
      console.log(comment.name);
      axios.post('https://backend.brightvisionchemicalmanufacturingplc.com.et/comment',{name:comment.name,email:comment.email,text:comment.text,id:id,date:Date().slice(10, 10)})
      .then(res=>{
        if(res.data.status==="success"){
     
      
      window.location.reload();
      setcomment({name:"",email:"",text:""
      })
    
        }
      })
      .catch(err=>console.log(err))
      
          }

          useEffect(() => {
            axios.get("https://backend.brightvisionchemicalmanufacturingplc.com.et/commentfetch/"+id)
            .then(res=>{
        if (res.data.status==="success") {
          console.log(res.data.Result);
          setcommentfetchd(res.data.Result.sort((a, b) => b.id - a.id).slice(0, 5))
            
        }
                
            })
            .catch(err=>console.log(err))
           
        }, [id]);
    


  return (
    <div>
    <Helmet>
    <title>News Detail- Be Right Vision</title>
    <meta name="description" content="Be Right Vision Manufacturing is a leading producer of household and personal care products dedicated to quality and customer satisfaction. From laundry detergents to hand washes, our products are trusted for their effectiveness and safety. We believe in making a difference through innovation and sustainability, serving communities with products that improve lives." />
  </Helmet>
    <div id="phone-tada" class="d-flex align-items-center justify-content-center me-4 fixed-bottom">
    <a href="tel:+251911351866" class="position-relative animated tada infinite">
        <i class="fa fa-phone-alt text-light fa-2x bg-danger rounded-circle p-3"></i>
        
        <span class="ring-wave"></span>
        <span class="ring-wave"></span>
    </a>
  </div>
    <div className=" bg-breadcrumb">
    <div className="bg-breadcrumb-single"></div>
    <div className="container text-center py-5" style={{maxWidth: '900px'}}>
        <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">News Detail</h4>
        <ol className="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
            <li className="breadcrumb-item"><a href="#">Pages</a></li>
            <li className="breadcrumb-item active text-primary">News </li>
        </ol>    
    </div>
   </div>
    

  <section id="blog" class="blog">
  <div class="" data-aos="fade-up">

    <div class="row  container-fluid px-0 mx-0">

      <div class="col-lg-8  container-fluid">

      {data.map((detail,i)=>{
		return	  <article class="entry entry-single" key={i}>

          <div class="entry-img">
            <img src={'https://backend.brightvisionchemicalmanufacturingplc.com.et/images/' + detail.image} alt="Bright news" class="w-100 object-fit-contain rounded"/>
          </div>

          <h2 class="entry-title">
            <Link className='dec text-decoration-none text-dark'>{detail.subject}</Link>
          </h2>

          <div class="entry-meta">
            <ul>
              
            <div class=" d-flex justify-content-end gap-3">
            <span class="  "><i class="bi bi-clock  loooa "></i> <Link className='dec text-decoration-none text-balck'><time datetime="2020-01-01" className=''>{detail.date}</time></Link></span>
            <span class=" "><b><i class="bi bi-person  loooa"></i> </b><Link className='dec text-decoration-none text-balck'><time datetime="2020-01-01" className=''>Admin</time></Link></span>
            <span class=""><i class="bi bi-chat-square-dots  loooa"></i> <Link className='dec text-decoration-none text-balck'><time datetime="2020-01-01" className=''>{commentfetchd.length}</time></Link></span>
            </div>
             
            </ul>
          </div>

          <div class="entry-content text-dark">
            
            <div dangerouslySetInnerHTML={{ __html: detail.detail}} />
           

         

         {detail.detail2 &&   <p>
              {detail.detail2}
            </p>}


         

          </div>

         
        </article>

    })}

        <div className="comments">

              <h4 className="comments-count">{commentfetchd.length} Comments</h4>

              {commentfetchd.map((cc,i)=>{ 
                return <div div id="comment-1" className="comment" key={i}>
                <div className="d-flex">
                   <div class="comment-img"><i class="bi bi-person h1"></i></div>
                  <div>
                    <h5><Link className='text-decoration-none'>{cc.name}</Link></h5>
                    <time dateTime="2020-01-01">{cc.date}</time>
                    <p>
                     {cc.ctext}
                    </p>
                  </div>
                </div>
              </div>})}

           
              

           

              <div className="reply-form">

                <h4>Leave Your Comment</h4>
                <p>Your email address will not be published. Required fields are marked * </p>
                <form onSubmit={commenthandle}>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input name="name" type="text" class="form-control"
                      value={comment.name} onChange={e => setcomment({ ...comment, name: e.target.value })}
                     placeHolder="Full Name*" required/>
                    </div>
                    <div className="col-md-6 form-group">
                      <input name="email" type="text" class="form-control"
                      value={comment.email} onChange={e => setcomment({ ...comment, email: e.target.value })}
                      placeHolder="Your Email*" required/>
                    </div>
                  </div>
                 
                  <div className="row">
                    <div className="col form-group">
                      <textarea name="comment" class="form-control"
                      value={comment.text} onChange={e => setcomment({ ...comment, text: e.target.value })}
                      placeHolder="Your Comment*" required></textarea>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-dark ">Post Comment</button>

                </form>

              </div>

            </div> 

      </div>
      <div class="col-lg-4 mr-0 px-2 rounded">

      <div class="sidebar  mx-0">

        


        <h3 class="sidebar-title text-dark">Recent Posts</h3>
        <div class="sidebar-item recent-posts">
        {latest.map((blog,i)=>{
          return   <div class="post-item clearfix" key={i}>
          <Link to={`/blogdetail/${blog.id}`} className='text-decoration-none '>
             <img src={'https://backend.brightvisionchemicalmanufacturingplc.com.et/images/' + blog.image} alt="Bright news"  class=" rounded"/>
             <h4><h6 class="text-decoration-none text-dark">{blog.subject}</h6></h4>
             <time datetime="2020-01-01" >{blog.date}</time>
             </Link>
           </div>
        }) }
      
         

  

   

      </div>

    </div>

  </div>


    </div>

  </div>
</section>


    
    </div>
  )
}

export default BlogDetail