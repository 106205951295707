import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'

function Blog() {


    const [data, setdata] = useState([]);
    useEffect(() => {
        axios.get("https://backend.brightvisionchemicalmanufacturingplc.com.et/blogfetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
  
    // Function to handle next page click
    const handleNextPage = () => {
      setCurrentPage((prevPage) => prevPage + 1);
    };
  
    // Function to handle previous page click
    const handlePrevPage = () => {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1)); // Ensure currentPage doesn't go below 1
    };
  
  return (
    <div className="  ">
    <Helmet>
    <title>News- Be Right Vision</title>
    <meta name="description" content="Be Right Vision Manufacturing is a leading producer of household and personal care products dedicated to quality and customer satisfaction. From laundry detergents to hand washes, our products are trusted for their effectiveness and safety. We believe in making a difference through innovation and sustainability, serving communities with products that improve lives." />
  </Helmet>

    <div id="phone-tada" class="d-flex align-items-center justify-content-center me-4 fixed-bottom">
    <a href="tel:+251911351866" class="position-relative animated tada infinite">
        <i class="fa fa-phone-alt text-light fa-2x bg-danger rounded-circle p-3"></i>
        
        <span class="ring-wave"></span>
        <span class="ring-wave"></span>
    </a>
  </div>

    <div className="container-fluid bg-blobreadcrumb">
    <div className="bg-blobreadcrumb-single"></div>
    <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
      <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s"></h4>
      <ol className="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
        <li className="breadcrumb-item"><a href="index.html"></a></li>
        <li className="breadcrumb-item"><a href="#"></a></li>
        <li className="breadcrumb-item active text-primary"></li>
        <li className="breadcrumb-item"><a href="index.html"></a></li>
        <li className="breadcrumb-item"><a href="#"></a></li>
        <li className="breadcrumb-item active text-primary"></li>
      </ol>
    </div>
  </div>
   
<div className="container mt-5">
        <div className=" row g-4 justify-content-center">
        {data.slice(startIndex, endIndex).map((blog,i)=>{
            return         <div className="col-md-6 col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.1s" key={i}>
                <div class="blog-item bg-light rounded p-4" style={{ backgroundImage: 'url(./img/bg.png)' }}>
                    <div className="mb-4">
                        <div className="d-flex justify-content-between">
                            <p className="mb-0"><span class="text-dark fw-bold">On</span> {blog.date}</p>
                            <p className="mb-0"><span class="text-dark fw-bold">By</span> Admin</p>
                        </div>
                    </div>
                    <div className="project-img">
                        <img src={'https://backend.brightvisionchemicalmanufacturingplc.com.et/images/' + blog.image} class="img-fluid w-100 rounded" alt="Bright news"/>
                        <div className="blog-plus-icon">
                          
                        </div>
                    </div>
                    <div className="my-4 text-center">
                    <Link to={`/blogdetail/${blog.id}`} className="h4">{blog.subject}</Link>
                </div>
                <div className='d-flex justify-content-center'>
                <Link to={`/blogdetail/${blog.id}`} class="btn btn-primary rounded-pill py-2 px-4 btn-center" >Explore More</Link>
                </div>
                </div>
            </div>
        })}
        </div>  

        <div className="d-flex pagination-wrap justify-content-center mt-5">
          
          
        <div className="custom-pagination d-flex  justify-content-center">
         <Link className="prev" onClick={handlePrevPage} disabled={currentPage === 1}>Prev</Link>
        <span className="d-flex  justify-content-center"> <p>{startIndex +1}</p>-<p>{endIndex}</p></span>
          {data.length>currentPage*5 ? <Link className="next" onClick={handleNextPage} disabled={endIndex >= data.length}>Next</Link>:<Link className="next" >Next</Link>}
        </div>
      
    </div>
        </div>
    </div>
  )
}

export default Blog