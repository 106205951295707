import './App.css';
import { Routes,Route,BrowserRouter} from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import { useEffect, useState } from 'react';
import { auth } from './firebase_con';
import Header from './Pages/Header';
import Footer from './Pages/Footer';
import Contact from './Pages/Contact';
import Blog from './Pages/Blog';
import BlogDetail from './Pages/BlogDetail';
import Pa404 from './Pages/Pa404';

import Products from './Pages/Products';
import OrderSi from './Pages/OrderSi';
import Teamsi from './Pages/Teamsi';
import Testimonialssi from './Pages/Testimonialssi';
import Faqsi from './Pages/Faqsi';
function App() {
const [user, setuser] = useState(null);
  useEffect(() => {
    auth.onAuthStateChanged((authUser)=>{
      if(authUser){
       
      setuser(authUser)

      }})
    }, []);
   console.log(user);
 return (
    <BrowserRouter>  

    <Routes>
   
    <Route path='/' element={<div><Header/><Home/><Footer/></div>} />
    <Route path='/home' element={<div><Header/><Home/><Footer/></div>} />
 <Route path='/about' element={<div><Header/><About/><Footer/></div>}/>
 <Route path='/contact' element={<div><Header/><Contact/><Footer/></div>}/>
 <Route path='/blogdetail/:id' element={<div><Header/><BlogDetail/><Footer/></div>}/>
 <Route path='/blog' element={<div><Header/><Blog/><Footer/></div>}/>
 <Route path='/products' element={<div><Header/><Products/><Footer/></div>}/>
 <Route path='/order' element={<div><Header/><OrderSi/><Footer/></div>}/>
 <Route path='/team' element={<div><Header/><Teamsi/><Footer/></div>}/>
 <Route path='/testimonials' element={<div><Header/><Testimonialssi/><Footer/></div>}/>
 <Route path='/faq' element={<div><Header/><Faqsi/><Footer/></div>}/>
 <Route path='*' element={<div><Header/><Pa404/><Footer/></div>}/>
  </Routes>

  </BrowserRouter>

  );
}

export default App;
