import React, { useEffect, useRef, useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import  product from './img/about-2.jpg'
import axios from 'axios';
import { Helmet } from 'react-helmet';
const HomeProducts = () => {
  const options = {
    loop: true,
    margin: 10,
    nav: false,
    dots: false,

    autoplayTimeout: 4000,
    autoplay: true,
  
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const carouselRef = useRef(); // Reference to the OwlCarousel

  const goToNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const goToPrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };


  const [data, setdata] = useState([]);
  useEffect(() => {
      axios.get("https://backend.brightvisionchemicalmanufacturingplc.com.et/productfetch")
      .then(res=>{
  if (res.data.status==="success") {
      setdata(res.data.Result.sort((a, b) => b.id - a.id))
      
  }
          
      })
      .catch(err=>console.log(err))
     
  }, []);

  return (
    <div className="product ">
    <Helmet>
    <title>Products- Be Right Vision</title>
    <meta name="description" content="Be Right Vision Manufacturing is a leading producer of household and personal care products dedicated to quality and customer satisfaction. From laundry detergents to hand washes, our products are trusted for their effectiveness and safety. We believe in making a difference through innovation and sustainability, serving communities with products that improve lives." />
  </Helmet>

    <div id="phone-tada" class="d-flex align-items-center justify-content-center me-4 fixed-bottom">
    <a href="tel:+251911351866" class="position-relative animated tada infinite">
        <i class="fa fa-phone-alt text-light fa-2x bg-danger rounded-circle p-3"></i>
        
        <span class="ring-wave"></span>
        <span class="ring-wave"></span>
    </a>
  </div>

      <div className="container-fluid px-0">
      <div className="container-fluid bg-probreadcrumb  mb-5">
    <div className="bg-probreadcrumb-single"></div>
    <div className="container text-center py-5" style={{maxWidth: '900px'}}>
        <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">Products</h4>
        <ol className="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
            <li className="breadcrumb-item"><a href="index.html"></a></li>
            <li className="breadcrumb-item"><a href="#"></a></li>
            <li className="breadcrumb-item active text-primary"></li>
            <li className="breadcrumb-item"><a href="index.html"></a></li>
            <li className="breadcrumb-item"><a href="#"></a></li>
            <li className="breadcrumb-item active text-primary"></li>
        </ol>    
    </div>
   </div>
   {data.length>0 &&   <OwlCarousel className="owl-theme" ref={carouselRef} {...options}>
        {data.map((products,i)=>{
          return <div class="d-block product-item rounded" key={i}>
          <img src={'https://backend.brightvisionchemicalmanufacturingplc.com.et/images/' + products.image} alt="like detergents "/>
          
          </div>
          
          
                  }) }
             
        </OwlCarousel>}
        <div className="d-flex justify-content-end px-3 my-3">
        <button className=" owl-prevw mx-1" onClick={goToPrev}>←</button>
        <button className=" owl-nextw" onClick={goToNext}>→</button>
      </div>
      </div>
    </div>
  );
};

export default HomeProducts;