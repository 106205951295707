import React from 'react'
import Faq from './Faq'
import { Helmet } from 'react-helmet'

function Faqsi() {
  return (
    <div className='container-fluid mx-0 px-0'>
    <Helmet>
    <title>Faq- Be Right Vision</title>
    <meta name="description" content="Be Right Vision Manufacturing is a leading producer of household and personal care products dedicated to quality and customer satisfaction. From laundry detergents to hand washes, our products are trusted for their effectiveness and safety. We believe in making a difference through innovation and sustainability, serving communities with products that improve lives." />
  </Helmet>
    <div className="container-fluid bg-faqbreadcrumb">
    <div className="bg-faqbreadcrumb-single"></div>
    <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
      <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s"></h4>
      <ol className="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
        <li className="breadcrumb-item"><a href="index.html"></a></li>
        <li className="breadcrumb-item"><a href="#"></a></li>
        <li className="breadcrumb-item active text-primary"></li>
        <li className="breadcrumb-item"><a href="index.html"></a></li>
        <li className="breadcrumb-item"><a href="#"></a></li>
        <li className="breadcrumb-item active text-primary"></li>
      </ol>
    </div>
  </div>

    <Faq/>
    </div>
  )
}

export default Faqsi