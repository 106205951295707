import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch, faDonate, faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons'; 
 import  brand from './img/logo.jpg'
const Header = () => {
    const [hover, setHover] = useState('');
    const navigate=useNavigate()
    const handleHover = (page) => {
        navigate(`/${page}`)
        setHover(page);
    };

    return (
        <div>
            {/* Top Bar */}
            <div className="container-fluid topbar px-0 d-none d-lg-block">
                <div className="container px-0">
                    <div className="row gx-0 align-items-center" style={{ height: '45px' }}>
                        <div className="col-lg-8 text-center text-lg-start mb-lg-0">
                            <div className="d-flex flex-wrap">
                                <Link to="" className="text-light me-4">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} className="text-light me-2" />
                                   Sheger city Melka Nono Sub city
                                </Link>
                                <Link to="" className="text-light me-4">
                                    <FontAwesomeIcon icon={faPhoneAlt} className="text-light me-2" />
                                    +251 911351866
                                </Link>
                                <Link to="" className="text-light me-0">
                                    <FontAwesomeIcon icon={faEnvelope} className="text-light me-2" />
                                    dejenefikadu@gmail.com
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 text-center text-lg-end">
                            <div className="d-flex align-items-center justify-content-end">
                            <a className="btn btn-sm btn-outline-light btn-square rounded-circle me-2 text-white bg-white"  href="mailto:dejenefikadu@gmail.com "><i class="bi bi-envelope text-primary"></i></a>
                            <Link className="btn btn-sm btn-outline-light btn-square rounded-circle me-2 text-primary bg-white" target="_blank" to={"https://www.facebook.com/dejene.fekadu "}><i class="fab fa-facebook-f fw-normal text-primary"></i></Link>
                            <Link className="btn btn-sm btn-outline-light btn-square rounded-circle me-2 text-primary bg-white" target="_blank" to={"https://wa.me/+251911351866 "}><i class="fab fa-whatsapp  fw-normal text-success"></i></Link>
                            <a className="btn btn-sm btn-outline-light btn-square rounded-circle me-2 bg-white" href="tel:0911351866 " ><i class="fas fa-phone text-dark"></i></a>
                          
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Navbar */}
            <div className="container-fluid sticky-top px-0">
                <div className="position-absolute bg-dark" ></div>
                <div className="container px-0">
                    <nav className="navbar navbar-expand-lg navbar-dark bg-white py-3 px-4">
                        <Link to={'/'} className="navbar-brand p-0">
                        
                           
                            <img src={brand} class="img-fluid logonfc" alt={" Bright logo"}/>
                           
                        </Link>
                        <Link  to={'/order'} className="btn btn-primary rounded-pill text-white py-2 px-4 navbar-toggler flex-wrap flex-sm-shrink-0"  >
                        Order →
                    </Link>
                        <Link className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                            <FontAwesomeIcon icon={faBars} />
                        </Link>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <div className="navbar-nav ms-auto pt-4 pt-md-0">
                                <Link to="/home" onClick={() => handleHover('home')} className={`${hover === "home" ? "active" : ""} nav-item nav-link`}  data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                                    Home
                                </Link>
                                <Link onClick={() => handleHover('about')} className={`${hover === "about" ? "active" : ""} nav-item nav-link`}  data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                                    About
                                </Link>
                                
                                <Link onClick={() => handleHover('products')} className={`${hover === "products" ? "active" : ""} nav-item nav-link`} data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                                    Products
                                </Link>
                                <div className="nav-item dropdown">
                                    <Link className=" nav-link dropdown-toggle"  data-bs-toggle="dropdown">
                                        Pages
                                    </Link>
                                    <div className="dropdown-menu m-0">
                                        <Link  className="dropdown-item"  onClick={() => handleHover('blog')}  data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Our Blog</Link>
                                        <Link  className="dropdown-item"  onClick={() => handleHover('team')}  data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Our Team</Link>
                                        <Link  className="dropdown-item" onClick={() => handleHover('testimonials')}  data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Testimonial</Link>
                                        <Link  className="dropdown-item" onClick={() => handleHover('faq')}  data-bs-toggle="collapse" data-bs-target="#navbarCollapse">FAQs</Link>
                                       
                                    </div>
                                </div>
                                <Link onClick={() => handleHover('contact')} className={`${hover === "contact" ? "active" : ""} nav-item nav-link mb-0 `}  data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                                    Contact
                                </Link>
                                <div className=" lery">
                               
                                <Link  onClick={() => handleHover('order')} className={`${hover === "null" ? "active" : ""} btn btn-primary rounded-pill text-white py-2 px-4  flex-wrap flex-sm-shrink-0`}  data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                                    Order →
                                </Link>
                            </div>

                            </div>
                          
                        </div>
                    </nav>
                </div>
            </div>
            
        </div>
    );
};

export default Header;
