import React, { useEffect, useState } from 'react'





import HomeAbout from './HomeAbout'

import HomeBlog from './HomeBlog'
import Team from './Team'
import Testimonials from './Testimonials'

import Faq from './Faq'



import Service from './Service'
import axios from 'axios'

import HomeProducts from './HomeProducts'
import Order from './Order'
import { Helmet } from 'react-helmet'



function Home() {

    const [data, setdata] = useState({

        name:"",
        email:'',
        phone:'',
        product:'',
        date:'',
        message:''
    });
    const [resp, setresp] = useState(null);
    
    const handleorder=(e)=>{
        e.preventDefault()
       
        axios.post('https://backend.brightvisionchemicalmanufacturingplc.com.et/order', {name:data.name,phone:data.phone,email:data.email,product:data.product,date:data.date,message:data.message})
          .then(res => {
            if (res.data.status==="success") {
            setresp(res.data.status)
            setdata({name:"",phone:"",email:"",product:"",date:"",message:""})
            }
          })
          .catch(err => console.log(err))
    
    
    }
    
  return (
    <div>
    <Helmet>
    <title>Home- Be Right Vision</title>
    <meta name="description" content="Be Right Vision Manufacturing is a leading producer of household and personal care products dedicated to quality and customer satisfaction. From laundry detergents to hand washes, our products are trusted for their effectiveness and safety. We believe in making a difference through innovation and sustainability, serving communities with products that improve lives." />
  </Helmet>

    <div id="phone-tada" class="d-flex align-items-center justify-content-center me-4 fixed-bottom">
    <a href="tel:+251911351866" class="position-relative animated tada infinite">
        <i class="fa fa-phone-alt text-light fa-2x bg-danger rounded-circle p-3"></i>
        
        <span class="ring-wave"></span>
        <span class="ring-wave"></span>
    </a>
  </div>


      

        


    
      



     
  <HomeAbout/>  


<HomeProducts/>
<HomeBlog/>

<Team/>


<Testimonials/>
<Faq/>


<div className=" section-title text-center pb-4 " data-wow-delay="0.1s">
<div className="sub-style ">
    <h5 className="sub-title text-primary px-2">Order</h5>
</div>

</div>
<Order/>

    </div>
  )
}

export default Home