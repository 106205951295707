import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

function Pa404() {
  return (
    <div>

    <Helmet>
    <title>404</title>
    <meta name="description" content="Be Right Vision Manufacturing is a leading producer of household and personal care products dedicated to quality and customer satisfaction. From laundry detergents to hand washes, our products are trusted for their effectiveness and safety. We believe in making a difference through innovation and sustainability, serving communities with products that improve lives." />
  </Helmet>
    <div class="container-fluid bg-light py-5">
    <div class="container py-5 text-center">
        <div class="row justify-content-center">
            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                <i class="bi bi-exclamation-triangle display-1 text-primary"></i>
                <h1 class="display-1">404</h1>
                <h1 class="mb-4">Page Not Found</h1>
                <p class="mb-4">We’re sorry, the page you have looked for does not exist in our website! Maybe go to our home page or try to use a search?</p>
                <Link class="btn btn-primary rounded-pill py-3 px-5" to={'/'}>Go Back To Home</Link>
            </div>
        </div>
    </div>
</div>
  </div>
  )
}

export default Pa404