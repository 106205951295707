import React, { useEffect, useState } from 'react'
import  faq from './img/faq.png'
import axios from 'axios';
function Faq() {

    const [data, setdata] = useState([]);
  
    useEffect(() => {
        axios.get("https://backend.brightvisionchemicalmanufacturingplc.com.et/faq")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);
  
   


  return (
    
    <div className="  faq">

    {data.length>0 &&     <div className=" section-title text-center  mb-0 " data-wow-delay="0.1s">
    <div className="sub-style ">
        <h5 className="sub-title text-primary px-2">FAQ</h5>
    </div>

</div>}
{data.length>0 &&   <div className="row align-items-center  ">
        <div className="col-lg-6 wow fadeInRight" data-wow-delay="0.3s">
                <div className="faq-img rounded">
                    <img src={faq} className="img-fluid rounded w-100" alt="Bright faq"/>
                   
                </div>
            </div>
            <div className="col-lg-6 wow fadeInLeft " data-wow-delay="0.1s">
           
                <div className="accordion bg-light rounded p-4" id="accordionExample">
                {data.map((faqdata,i)=>{

                    return <div className="accordion-item border-0 mb-4" key={i}>
                        <h2 className="accordion-header" id={`heading${faqdata.id}`}>
                            <button className="accordion-button text-dark fs-5 collapsed fw-bold rounded-top" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${faqdata.id}`} aria-expanded="true" aria-controls={`collapse${faqdata.id}`}>
                               {faqdata.question}
                            </button>
                        </h2>
                        <div id={`collapse${faqdata.id}`} className="accordion-collapse collapse " aria-labelledby={`heading${faqdata.id}`} data-bs-parent="#accordionExample">
                            <div className="accordion-body my-2">
                                
                                <p>{faqdata.answer}</p>
                            </div>
                        </div>
                    </div>})}

                   
                </div>
            </div>

            
        </div>}
    </div>



  )
}

export default Faq