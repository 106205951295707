import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'
const Order = () => {
    const [data, setdata] = useState({

        name:"",
        email:'',
        phone:'',
        product:'',
        date:'',
        message:'',
        address:''
    });
    const [resp, setresp] = useState(null);
    
    const handleorder=(e)=>{
        e.preventDefault()
       
        axios.post('https://backend.brightvisionchemicalmanufacturingplc.com.et/createorder', {name:data.name,phone:data.phone,email:data.email,product:data.product,date:data.date,message:data.message,address:data.address})
          .then(res => {
            if (res.data.status==="success") {
            setresp(res.data.status)
            setdata({name:"",phone:"",email:"",product:"",date:"",message:"",address:""})
            }
          })
          .catch(err => console.log(err))
    
    
    }
    return (
        <div class="container-fluid appointment py-5">
        <div class="container py-5">
            <div class="row g-5 align-items-center">
                <div class="col-lg-6 wow fadeInLeft " data-wow-delay="0.2">
                   
                <div className="animated-content text-light">
                <h1 className="fade-in text-light mb-3 display-5">Order Our Quality Products</h1>
                <p className="fade-in  text-light mb-3">
                  Bright Vision offers a range of premium cleaning products to meet your household needs. Place your order today and experience the difference!
                </p>
                <p className="fade-in  text-light mb-3">We value quality and customer satisfaction above all.</p>
              </div>

              <div class="text-start mb-4  d-flex justify-content-center">
              <Link to={'/products'} class="btn btn-light rounded-pill text-white py-3 px-5">View Products</Link>
          </div>
                </div>
                <div class="col-lg-6 wow fadeInRight" data-wow-delay="0.4s">
                <div className="appointment-form rounded ">

                <h1 className="display-5 text-light d-flex justify-content-center">Get Order</h1>
                <form onSubmit={handleorder}>
                { resp? <div className="alert alert-success d-flex justify-content-center" role="alert">
                    <strong>Your Order has been Placed. Thank you!</strong>
                </div>:<div><br/><br/><br/></div>}
                    <div className="row gy-3 gx-4">
                        <div className="col-xl-6">
                            <input type="text" 
                            value={data.name} onChange={e => setdata({ ...data, name: e.target.value })}
                            required
                                className="form-control py-3 border-dark bg-light   text-dark"
                                placeholder="Full Name"
                            />
                        </div>
                        <div className="col-xl-6">
                            <input type="email" 
                            value={data.email} onChange={e => setdata({ ...data, email: e.target.value })}
                            required
                                className="form-control py-3  border-dark bg-light    text-dark"
                                placeholder="Email"
                            />
                        </div>
                        <div className="col-xl-6">
                            <input type="number" 
                            value={data.phone} onChange={e => setdata({ ...data, phone: e.target.value })}
                            required
                                className="form-control py-3  border-dark bg-light   text-dark"
                                placeholder="Phone"
                            />
                        </div>
                        <div className="col-xl-6">
                        <input type="text" 
                        value={data.address} onChange={e => setdata({ ...data, address: e.target.value })}
                        required
                            className="form-control py-3 border-dark bg-light   text-dark"
                            placeholder="Enter Your Address"
                        />
                    </div>
                        <div className="col-xl-6">
                        <input
                            type="date"
                            value={data.date} onChange={e => setdata({ ...data, date: e.target.value })}
                            required
                            className="form-control py-3  border-dark bg-light   text-dark"
                        />
                    </div>
                        <div class="col-xl-6">
                        <select class="form-control py-3  border-dark bg-light   text-dark" aria-label="Default select example"
                        value={data.product} onChange={e => setdata({ ...data, product: e.target.value })}
                        required
                        >
                            <option value="Shampoo">Shampoo</option>
                            <option value="Bleach">Bleach</option>
                            <option value="Paraffin">Paraffin</option>
                            <option value="Glass Cleaner">Glass Cleaner</option>
                            <option value="Hand Wash">Hand Wash</option>
                            <option value="Dettol">Dettol</option>
                        </select>
                    </div>

                 

                        
                        <div className="col-12">
                            <textarea
                            value={data.message} onChange={e => setdata({ ...data, message: e.target.value })}
                            required
                                className="form-control  border-dark bg-light  text-dark"
                                cols="30"
                                rows="3"
                                placeholder="Message"
                            ></textarea>
                        </div>
                        <div className="col-12">
                            <button type="submit" className="btn btn-danger text-white w-100 py-3 px-5">SUBMIT NOW</button>
                        </div>
                    </div>
                </form>
                
            </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default Order;
