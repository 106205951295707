import React, { useEffect, useRef, useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import  product from './img/about-2.jpg'
import axios from 'axios';
const HomeProducts = () => {
  const options = {
    loop: true,
    margin: 10,
    nav: false,
    dots: false,

    autoplayTimeout: 4000,
    autoplay: true,
  
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const carouselRef = useRef(); // Reference to the OwlCarousel

  const goToNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const goToPrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  const [data, setdata] = useState([]);
  useEffect(() => {
      axios.get("https://backend.brightvisionchemicalmanufacturingplc.com.et/productfetch")
      .then(res=>{
  if (res.data.status==="success") {
      setdata(res.data.Result.sort((a, b) => b.id - a.id))
      
  }
          
      })
      .catch(err=>console.log(err))
     
  }, []);

  return (
    <div className="product ">
    {data.length>0 &&  <div className="container-fluid ">
      <div className=" section-title text-center   mb-3" data-wow-delay="0.1s">
      <div className="sub-style ">
          <h5 className="sub-title text-primary px-2">Products</h5>
      </div>
  
     
  </div>
        <OwlCarousel className="owl-theme" ref={carouselRef} {...options}>
        {data.map((products,i)=>{
return <div class="d-block product-item rounded" key={i}>
<img src={'https://backend.brightvisionchemicalmanufacturingplc.com.et/images/' + products.image} alt="beright detergents"/>

</div>


        }) }
        </OwlCarousel>
        <div className="d-flex justify-content-end px-3 my-3">
        <button className=" owl-prevw mx-1" onClick={goToPrev}>←</button>
        <button className=" owl-nextw" onClick={goToNext}>→</button>
      </div>
      </div>}
    </div>
  );
};

export default HomeProducts;