import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
 import  aboutImage from './img/logo.jpg'

import axios from 'axios'
import Counter from './counter'
function HomeAbout() {

    const [data, setdata] = useState({
        happy:0,
        projects:0,
        workers:0
    
      });
    
   console.log(data);
   
      useEffect(() => {
          axios.get("https://backend.brightvisionchemicalmanufacturingplc.com.et/numberfetch")
          .then(res=>{
      if (res.data.status==="success") {
          setdata({
            happy:res.data.Result[0].happy,
            projects:res.data.Result[0].projects,
            workers:res.data.Result[0].workers
    
    
          })
      
      }
              
          })
          .catch(err=>console.log(err))
         
      }, []);



  return (
  
    <div className="container-fluid about bg-light pb-5 px-0">
    <div className="container">
    <div className=" section-title text-center   mb-3" data-wow-delay="0.1s">
    <div className="sub-style ">
        <h5 className="sub-title text-primary px-2">About</h5>
    </div>

</div>
        <div className="row g-md-5 align-items-center ">
            <div className="col-lg-6 col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
                
                    <img src={aboutImage} className="img-fluid w-100 rounded-top bg-white mb-4" alt="Bright vision"/>
                   
               
            </div>
            <div className="col-lg-6 col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
           
                <p className="text ps-4 mb-4">Be Right Vision Manufacturing is a leading producer of household and personal care products dedicated to quality and customer satisfaction. From laundry detergents to hand washes, our products are trusted for their effectiveness and safety. We believe in making a difference through innovation and sustainability, serving communities with products that improve lives.
                </p>
                <div className="row g-4 justify-content-between mb-5">
                    <div className="col-lg-6 col-xl-5">
                        <p className="text-dark"><i className="fas fa-check-circle text-primary me-1"></i> Be Right</p>
                        <p className="text-dark mb-0"><i className="fas fa-check-circle text-primary me-1"></i>Like</p>
                    </div>
                    <div className=" d-flex justify-content-center mb-5">
                    <div className=""><Link to={'/about'} class="btn btn-primary rounded-pill py-3 px-5">Discover More</Link></div>
                  
                </div>
              
                    <div className="col-sm-4">
                        <div className="aboutborder rounded p-4">
                            <div className="d-flex align-items-center justify-content-center">
                                <span className="counter-value fs-1 fw-bold text-info" data-toggle="counter-up"><Counter start={1} end={data.happy} duration={5} /></span>
                                <h4 class="text-dark fs-1 mb-0" style={{ fontWeight: 600, fontSize: '25px' }}>+</h4>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-center">
                                <p className="text-dark mb-0">Happy Clients</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-sm-4">
                        <div className="aboutborder rounded p-4">
                            <div className="d-flex align-items-center justify-content-center">
                                <span className="counter-value fs-1 fw-bold text-info" data-toggle="counter-up"><Counter start={1} end={24} duration={3} /></span>
                                
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-center">
                                <p className="text-dark mb-0">Working Hours</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="aboutborder rounded p-4">
                            <div className="d-flex align-items-center justify-content-center">
                                <span className="counter-value fs-1 fw-bold text-info" data-toggle="counter-up"><Counter start={1} end={data.projects} duration={5} /></span>
                                <h4 class="text-dark fs-1 mb-0" style={{ fontWeight: 600, fontSize: '25px' }}>+</h4>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-center">
                                <p className="text-dark mb-0">Team Members</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
           
   
    
    
  
  )
}

export default HomeAbout