import React from 'react';
import { Carousel, Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaHistory, FaBullseye, FaHandsHelping, FaArrowRight, FaArrowLeft } from 'react-icons/fa';

 import  aboutImage from './img/logo.jpg'
import Team from './Team';
import { Helmet } from 'react-helmet';

const sections = [
  {
    title: "About Be Right Vision",
    text: "Be Right Vision Manufacturing is a leading producer of household and personal care products dedicated to quality and customer satisfaction. From laundry detergents to hand washes, our products are trusted for their effectiveness and safety. We believe in making a difference through innovation and sustainability, serving communities with products that improve lives.",
    icon:  <FaHistory className="section-icon" />,

  },
  {
    title: "Our Vision",
    text: "To be a household name known for delivering exceptional and environmentally friendly personal care and home care products that enhance the lives of our customers, while promoting a cleaner, safer, and healthier world.",
    icon: <FaBullseye  className="section-icon"/>,
  },
  {
    title: "Our Mission",
    text: "At Be Right Vision, our mission is to provide high-quality, reliable, and affordable products that meet the everyday needs of households and businesses. We are committed to rigorous quality control, innovation, and sustainability in every step of our manufacturing process, ensuring that each product is crafted with care and responsibility.",
    icon: <FaBullseye  className="section-icon"/>,
  },
  {
    title: "Our Values",
    text: (
      <ul>
        <li><b>Quality and Reliability:</b></li>
        <p> We deliver consistent, high-quality products that our customers can rely on.</p>
        <li><b>Customer Satisfaction:</b></li>
      
      <p>Our commitment to excellence drives us to exceed customer expectations at every step.</p>
      
        <li><b>Innovation and Improvement:</b></li>
    
      <p>  We embrace continuous improvement and innovation to meet evolving needs.</p>


    
      </ul>
     
     
      
      
      
      
      
      
      
      
      
      
      
      
      

    ),
    icon: <FaHandsHelping  className="section-icon"/>,
  },
];


const About = () => {
  const carouselRef = React.useRef(null);

  const handleNext = () => carouselRef.current.next();
  const handlePrev = () => carouselRef.current.prev();

  return (
  <div>
  <Helmet>
  <title>About Us - Be Right Vision</title>
  <meta name="description" content="Be Right Vision Manufacturing is a leading producer of household and personal care products dedicated to quality and customer satisfaction. From laundry detergents to hand washes, our products are trusted for their effectiveness and safety. We believe in making a difference through innovation and sustainability, serving communities with products that improve lives." />
</Helmet>

  <div id="phone-tada" class="d-flex align-items-center justify-content-center me-4 fixed-bottom">
  <a href="tel:+251911351866" class="position-relative animated tada infinite">
      <i class="fa fa-phone-alt text-light fa-2x bg-danger rounded-circle p-3"></i>
      
      <span class="ring-wave"></span>
      <span class="ring-wave"></span>
  </a>
</div>

    <div className="container-fluid bg-abbreadcrumb">
      <div className="bg-abbreadcrumb-single"></div>
      <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
        <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s"></h4>
        <ol className="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
          <li className="breadcrumb-item"><a href="index.html"></a></li>
          <li className="breadcrumb-item"><a href="#"></a></li>
          <li className="breadcrumb-item active text-primary"></li>
          <li className="breadcrumb-item"><a href="index.html"></a></li>
          <li className="breadcrumb-item"><a href="#"></a></li>
          <li className="breadcrumb-item active text-primary"></li>
        </ol>
      </div>
    </div>
    <Container fluid className="mt-5">
      <Row>
        {/* Left Side Image */}
        <Col md={6} className="image-container">
          <img src={aboutImage} alt="Bright vision" className="img-fluid lotaa" />
        </Col>

        {/* Right Side Carousel */}
        <Col md={6} className="carousel-container">
          <Carousel  ref={carouselRef} controls={false} indicators={false}>
            {sections.map((section, idx) => (
              <Carousel.Item key={idx}>
                <Card className="content-card">
                  <Card.Body>
                    <div className="d-flex align-items-center mx-3">
                      {section.icon}
                      <h2 className="ml-2">{section.title}</h2>
                    </div>
                    <div className="section-text">
                      {typeof section.text === 'string' ? <p>{section.text}</p> : section.text}
                    </div>
                  </Card.Body>
                </Card>
              </Carousel.Item>
            ))}
          </Carousel>
          <div className="carousel-buttons d-flex justify-content-end mt-4">
              <Button variant="primary" className="circular-btn mx-3" onClick={handlePrev}>
                <FaArrowLeft />
              </Button>
              <Button variant="primary" className="circular-btn" onClick={handleNext}>
                <FaArrowRight />
              </Button>
            </div>
        </Col>
      </Row>

      <Team />
    </Container>
  </div>
);
};

export default About;
